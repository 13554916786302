import { createTheme } from '@mui/material';

const tabletMedia = '@media (max-width: 1024px)';
const mobileMedia = '@media (max-width: 430px)';

export const theme = createTheme({
   components: {
      MuiButton: {
         styleOverrides: {
            root: {
               display: 'flex',
               flexDirection: 'row',
               justifyContent: 'space-between',
               maxHeight: '48px',
               minWidth: '185px',
               height: '100%',
               padding: '16px 5px 16px 16px',
               background: 'white',
               outline: '2px solid rgba(255, 255, 255, 0.4)',
               borderRadius: '100px',
               '-webkit-tap-highlight-color': 'transparent',
               outline: 'none',

               '&:hover': {
                  backgroundColor: 'rgb(196, 196, 203)',
               },
               '&:active': {
                  '-webkit-tap-highlight-color': 'transparent',
                  outline: 'none',
                  backgroundColor: 'rgb(166, 166, 177)',
               },

               [tabletMedia]: {
                  minWidth: 139,
               },
            },
         },
         defaultProps: {
            disableRipple: true,
         },
      },
      MuiTypography: {
         styleOverrides: {
            root: {
               fontFamily: 'Geologica',
               color: 'white',
               fontWeight: 500,
               letterSpacing: 0,
            },
         },
         variants: [
            {
               props: { variant: 'layout_text' },
               style: {
                  fontSize: '18px',
                  lineHeight: 'auto',
                  lineHeight: '23px',
                  cursor: 'pointer',
                  margin: '0px 10px',
                  padding: '10px',

                  '&:hover': {
                     color: 'rgba(255, 255, 255, 0.7)',
                  },
                  '&:active': {
                     color: 'rgba(255, 255, 255, 0.4)',
                  },
               },
            },
            {
               props: { variant: 'main_title' },
               style: {
                  fontSize: '100px',
                  lineHeight: '125%',
                  [tabletMedia]: {
                     fontSize: '60px',
                  },
                  [mobileMedia]: {
                     textAlign: 'start',
                     fontSize: '56px',
                     lineHeight: '110%',
                  },
               },
            },
            {
               props: { variant: 'main_description' },
               style: {
                  color: 'rgb(207, 206, 212)',
                  fontFamily: 'Onest',
                  fontWeight: 500,
                  fontSize: '23px',
                  lineHeight: '140%',
                  [tabletMedia]: {
                     fontSize: '14px',
                  },
               },
            },
            {
               props: { variant: 'block_title' },
               style: {
                  fontSize: '32px',
                  fontWeight: 400,
                  lineHeight: '30px',
                  letterSpacing: '0%',
                  [tabletMedia]: {
                     fontSize: '24px',
                  },
               },
            },
            {
               props: { variant: 'card_title' },
               style: {
                  fontSize: '28px',
                  fontWeight: 400,
                  lineHeight: '140%',
                  letterSpacing: '0%',
                  [tabletMedia]: {
                     fontSize: '22px',
                  },
               },
            },
            {
               props: { variant: 'card_modal_title' },
               style: {
                  fontSize: '42px',
                  fontWeight: 400,
                  lineHeight: '140%',
               },
            },
            {
               props: { variant: 'card_description' },
               style: {
                  color: 'rgba(255, 255, 255, 0.7)',
                  fontFamily: 'Onest',
                  fontSize: '16px',
                  lineHeight: '140%',
                  [tabletMedia]: {
                     fontSize: '12px',
                  },
                  [mobileMedia]: {
                     fontSize: '14px',
                  },
               },
            },
            {
               props: { variant: 'card_modal_description' },
               style: {
                  color: 'rgba(255, 255, 255, 0.7)',
                  fontFamily: 'Onest',
                  fontSize: '16px',
                  lineHeight: '150%',
               },
            },
            {
               props: { variant: 'user_name' },
               style: {
                  fontSize: '38px',
                  lineHeight: '120%',
                  [tabletMedia]: {
                     fontSize: '22px',
                  },
               },
            },
            {
               props: { variant: 'user_achievements' },
               style: {
                  fontFamily: 'Onest',
                  fontWeight: 'bold',
                  fontSize: '18px',
                  lineHeight: '120%',
                  [tabletMedia]: {
                     fontSize: '16px',
                  },
               },
            },
            {
               props: { variant: 'user_info' },
               style: {
                  color: 'rgb(182, 179, 192)',
                  fontFamily: 'Onest',
                  fontSize: '16px',
                  fontWeight: 500,
                  lineHeight: '150%',
                  [tabletMedia]: {
                     fontSize: '12px',
                  },
                  [mobileMedia]: {
                     fontSize: '14px',
                  },
               },
            },
            {
               props: { variant: 'career_card_top_text' },
               style: {
                  color: 'rgb(184, 184, 187)',
                  fontFamily: 'Onest',
                  fontSize: '16px',
                  fontWeight: 500,
                  lineHeight: '140%',
               },
            },
            {
               props: { variant: 'career_card_title' },
               style: {
                  fontSize: '32px',
                  lineHeight: '140%',
                  letterSpacing: '0%',
                  [tabletMedia]: {
                     fontSize: '22px',
                  },
               },
            },
            {
               props: { variant: 'button_text' },
               style: {
                  color: 'rgb(0, 0, 0)',
                  fontSize: '18px',
                  lineHeight: 'auto',
                  letterSpacing: '0%',
                  textAlign: 'left',
                  textTransform: 'initial',
               },
            },
         ],
      },
   },
   breakpoints: {
      values: {
         mobile: 430,
         tablet: 1024,
         desktop: 1728,
      },
   },
});
